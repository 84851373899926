import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import ImpactIcon from "./ImpactIcon"
import { ImpactType } from "./ImpactDashboard"
import CountUp from "react-countup"
import { SsmgType } from "model"

const Container = styled.div`
  position: relative;
`
const IconContainer = styled.div<{ color: string }>`
  position: absolute;
  top: 20px;
  left: 0px;
  border-radius: 100px;
  background-color: ${({ color }) => color};
  width: 100px;
  height: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImpactIcon = styled(ImpactIcon)`
  color: white;
  width: 70%;
  text-transform: uppercase;
  font-weight: bold;
  svg {
    margin-bottom: 5px;
    path {
      fill: white;
      stroke: white;
    }
  }
`

const Body = styled.div`
  height: 140px;
  width: 350px;
  background-color: white;
  border-radius: 20px;
  padding-left: 75px;
  margin-left: 50px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IconDiv = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 0;
  }
`

const Heading = styled.div`
  font-family: "CaflischScriptPro", "Verdana", sans-serif;
`
const Amount = styled.div<{ color: string }>`
  font-family: "Minion", "Verdana", sans-serif;
  color: ${({ color }) => color};
  font-size: 1.8em;
`
const Description = styled.div`
  font-size: 0.7em;
`

interface Props {
  className?: string
  color: string
  impact: ImpactType
  ssmgs: { [key: string]: SsmgType }
}

const ImpactTile: FC<Props> = ({ className, impact, color, ssmgs }) => {
  const { labelB1, labelB2, labelB3, ssmg } = impact.impactType
  const amount =
    labelB2 === "£" ? `£${impact.amount.toLocaleString()}` : `${impact.amount.toLocaleString()}`
  const description = labelB2 === "£" ? labelB3 : `${labelB2} ${labelB3}`
  return (
    <Container className={className}>
      <IconContainer color={color}>
        <StyledImpactIcon ssmg={ssmgs[ssmg]} includeLabel />
        {/*<IconDiv>{Icon && <Icon />}</IconDiv>*/}
      </IconContainer>
      <Body>
        <Heading>{labelB1}</Heading>
        <Amount color={color}>
          {labelB2 === "£" && "£"}
          <CountUp end={impact.amount} />
        </Amount>
        {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
      </Body>
    </Container>
  )
}

export default ImpactTile
