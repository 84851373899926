import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import GInvertedLogo from "../../../gatsby/components/GInvertedLogo"
import ProfileMenu from "./ProfileMenu"
import HeaderMenu from "./HeaderMenu"
import { LoginContext, Portal, UserSelector } from "components"
import { getConfig } from "../../../config"
import { colors, zIndexs } from "model"

export const HEADER_HEIGHT = 50
const Container = styled.div`
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
  background-color: ${colors.primaryDarker};
  color: white;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: ${zIndexs.headerZIndex};
  overflow: visible;
`
const LogoContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  i {
    height: 26px;
    margin-right: 10px;
  }
`
const MessageContainer = styled.div`
  background-color: ${colors.rouge};
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 0.7em;
`
const UserSelectorContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 250px;
  z-index: 1000;
`

interface Props {}

const Header: FC<Props> = () => {
  const { user, loggedInUser } = useContext(LoginContext)
  return (
    <Container>
      <LogoContainer>
        <GInvertedLogo />
      </LogoContainer>
      {user && <HeaderMenu />}
      {loggedInUser && loggedInUser.roles.includes("administrator") && (
        <Portal>
          <UserSelectorContainer>
            <UserSelector baseUrl={getConfig().baseUrl} />
          </UserSelectorContainer>
        </Portal>
      )}
      {user && <ProfileMenu />}
    </Container>
  )
}

export default Header
