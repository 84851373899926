import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { Checkbox, Table } from "rsuite"
import { ImpactOrderType } from "model"
const { Column, HeaderCell, Cell } = Table

const Container = styled.div``
const StyledTable = styled(Table)`
  font-size: 12px;
`

interface CheckCellProps {
  rowData?: ImpactOrderType
  onChange: () => void
  checkedKeys: []
  dataKey: any
}

const CheckCell: FC<CheckCellProps> = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item === rowData[dataKey])}
      />
    </div>
  </Cell>
)

interface Props {
  orders: ImpactOrderType[]
}

const ImpactOrderTable: FC<Props> = ({ orders }) => {
  const [checkedKeys, setCheckedKeys] = React.useState([])
  return (
    <Container>
      <StyledTable
        height={500}
        data={orders}
        onRowClick={rowData => {
          console.log(rowData)
        }}
      >
        <Column width={50} align="center">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: "40px" }}>
              <Checkbox inline checked={true} indeterminate={false} onChange={() => {}} />
            </div>
          </HeaderCell>
          <CheckCell dataKey="id" checkedKeys={[]} onChange={() => {}} />
        </Column>
        <Column>
          <HeaderCell>Source</HeaderCell>
          <Cell dataKey="source" />
        </Column>
        <Column>
          <HeaderCell>Reference</HeaderCell>
          <Cell dataKey="reference" />
        </Column>
        <Column>
          <HeaderCell>Supplier</HeaderCell>
          <Cell dataKey="supplierCode" />
        </Column>
      </StyledTable>
    </Container>
  )
}

export default ImpactOrderTable
