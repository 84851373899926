import { ImpactResponseType } from "../../api/impactApi"
import PdfImpactDataType, {
  PdfProblemImpactType,
  PdfSupplierType,
} from "../../model/PdfImpactDataType"
import { OrderType } from "./ImpactDashboard"
import { ImpactOrderType, ImpactSupplierType, ProductImpactType } from "model"

export const getOrders = (orderImpacts: ImpactOrderType[]): OrderType[] => {
  const map = {}
  orderImpacts.forEach(orderImpact => {
    const key = `${orderImpact.source}-${orderImpact.reference}`
    if (!map[key]) {
      map[key] = {
        key,
        date: orderImpact.date,
        source: orderImpact.source,
        reference: orderImpact.reference,
        spend: 0,
        quantity: 0,
      }
    }

    map[key].spend += orderImpact.spend
    map[key].quantity += orderImpact.quantity
  })

  return Object.values(map)
}

export const getSpendBySupplier = (orders: ImpactOrderType[]): { [key: string]: number } => {
  const result: { [key: string]: number } = {}
  return orders.reduce((result, order) => {
    if (!result[order.supplierCode]) {
      result[order.supplierCode] = 0
    }

    result[order.supplierCode] += order.spend

    return result
  }, result)
}

export const getSpendByAttribute = (
  supplierSpend: { [key: string]: number },
  supplierMap: { [key: string]: ImpactSupplierType },
  attribute: string
): { [key: string]: number } => {
  const result: { [key: string]: number } = {}
  Object.keys(supplierSpend).forEach(supplierCode => {
    const supplier = supplierMap[supplierCode]
    if (supplier) {
      const att = supplier[attribute]
      if (att) {
        const spend = supplierSpend[supplierCode]
        if (!result[att]) {
          result[att] = 0
        }
        result[att] += spend
      }
    }
  })

  return result
}

export const getSpendByMultiAttribute = (
  supplierSpend: { [key: string]: number },
  supplierMap: { [key: string]: ImpactSupplierType },
  attribute: string
): { [key: string]: number } => {
  const result: { [key: string]: number } = {}
  Object.keys(supplierSpend).forEach(supplierCode => {
    const supplier = supplierMap[supplierCode]
    if (supplier) {
      const attArray = supplier[attribute] || []
      const spend = supplierSpend[supplierCode]

      const proportionateSpend = attArray.length > 0 ? spend / attArray.length : 0

      attArray.forEach(att => {
        if (!result[att]) {
          result[att] = 0
        }
        result[att] += proportionateSpend
      })
    }
  })

  return result
}

export const mapForPdf = (impactResponse: ImpactResponseType): PdfImpactDataType => {
  return {
    suppliers: mapObject(impactResponse.suppliers, mapSupplier, impactResponse),
    spendImpact: {
      gender: getMultiSpendImpact(impactResponse, "founderGenders"),
      legalStructure: getSingleSpendImpact(impactResponse, "legalStructure"),
      employees: getSingleSpendImpact(impactResponse, "employees"),
      ethnicity: getMultiSpendImpact(impactResponse, "founderEthnicities"),
      region: getSingleSpendImpact(impactResponse, "region"),
      suppliers: getSingleSpendImpact(impactResponse, "code"),
      sdgs: getSdgSPendImpact(impactResponse),
    },
    productImpact: getProblemSupplierImpacts(impactResponse),
  }
}

const getProblemSupplierImpacts = (impactResponse: ImpactResponseType): PdfProblemImpactType[] => {
  const problemSupplierImpactMap = {}
  impactResponse.orderImpacts.forEach(orderImpact => {
    const supplier = impactResponse.suppliers[orderImpact.supplierCode]
    const productGroup = supplier.productGroups[orderImpact.productGroupCode]
    if (productGroup) {
      productGroup.impacts.forEach(impact => {
        const impactType = impactResponse.impactTypes[impact.impactCode]
        const impactAmount = getSupplierImpactForOrder(impact, orderImpact)
        const key = `${impactType.problem}|${orderImpact.supplierCode}|${impactType.code}`
        if (!problemSupplierImpactMap[key]) {
          problemSupplierImpactMap[key] = 0
        }
        problemSupplierImpactMap[key] += impactAmount
      })
    }
  })

  const resultMap: { [key: string]: PdfProblemImpactType } = {}

  Object.keys(problemSupplierImpactMap).forEach(key => {
    const amount = problemSupplierImpactMap[key]
    const keys = key.split("|")
    const problem = impactResponse.problems[keys[0]]
    const supplier = impactResponse.suppliers[keys[1]]
    const impactType = impactResponse.impactTypes[keys[2]]
    if (!resultMap[problem.code]) {
      resultMap[problem.code] = {
        problem: {
          name: problem.name,
          problemStatement: problem.problemStatement,
          solutionStatement: problem.solutionStatement,
          sdgs: problem.sdgs.map(sdgCode => impactResponse.sdgs[sdgCode].ID),
          stats: problem.stats,
        },
        impacts: [],
      }
    }

    resultMap[problem.code].impacts.push({
      amount: amount,
      supplier: supplier.code,
      impact: {
        ID: impactType.ID,
        airtableId: impactType.airtableId,
        name: impactType.name,
        problemAirtableId: "",
        labelA1: impactType.labelA1,
        labelA2: impactType.labelA2,
        labelB1: impactType.labelB1,
        labelB2: impactType.labelB2,
        labelB3: impactType.labelB3,
        impactType: impactResponse.ssmgs[impactType.ssmg].code,
      },
    })
  })

  return Object.values(resultMap)
}

const getSdgSPendImpact = (impactResponse: ImpactResponseType): number[] => {
  const sdgSpend = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  impactResponse.orderImpacts.forEach(orderImpact => {
    const supplier = impactResponse.suppliers[orderImpact.supplierCode]
    const perSdgSpend = orderImpact.spend / supplier.sdgs.length
    supplier.sdgs.forEach(sdgCode => {
      const sdg = impactResponse.sdgs[sdgCode]
      sdgSpend[sdg.ID] += perSdgSpend
    })
  })
  return sdgSpend
}

const getMultiSpendImpact = (
  impactResponse: ImpactResponseType,
  attributeName: string
): { [key: string]: number } => {
  const result = {}
  impactResponse.orderImpacts.forEach(orderImpact => {
    const supplier = impactResponse.suppliers[orderImpact.supplierCode]
    const spend = orderImpact.spend / supplier[attributeName].length
    supplier[attributeName].forEach((attributeValue: string) => {
      if (attributeValue) {
        if (!result[attributeValue]) {
          result[attributeValue] = 0
        }
        result[attributeValue] += spend
      }
    })
  })
  return result
}

const getSingleSpendImpact = (
  impactResponse: ImpactResponseType,
  attributeName: string
): { [key: string]: number } => {
  const result = {}
  impactResponse.orderImpacts.forEach(orderImpact => {
    const supplier = impactResponse.suppliers[orderImpact.supplierCode]
    const attributeValue = supplier[attributeName]
    if (attributeValue) {
      if (!result[attributeValue]) {
        result[attributeValue] = 0
      }
      result[attributeValue] += orderImpact.spend
    }
  })
  return result
}

const mapSupplier = (
  supplier: ImpactSupplierType,
  impactResponse: ImpactResponseType
): PdfSupplierType => {
  const { sdgs, ssmgs, certifications } = impactResponse
  const {
    name,
    code,
    logoSrc,
    oneLineDescription,
    problemStatement,
    problemDescription,
    featureImageSrc,
    featureDescription,
    founderGenders,
    founderEthnicities,
    legalStructure,
    employees,
    region,
    stats,
  } = supplier
  return {
    name,
    slug: code,
    logoSrc,
    oneLiner: oneLineDescription,
    problemStatement,
    problemDescription,
    featureImageSrc,
    featureDescription,
    impacts: stats,
    sdgs: supplier.sdgs.map(sdg => {
      const fullSdg = sdgs[sdg]
      return fullSdg.ID
    }),
    ssmgs: supplier.ssmgs.map(ssmg => {
      const fullSsmg = ssmgs[ssmg]
      return {
        slug: fullSsmg.code,
        name: fullSsmg.name,
      }
    }),
    certifications: supplier.certifications.map(certification => {
      const full = certifications[certification]
      return {
        name: full.name,
        slug: full.code,
        logoSrc: full.logoSrc,
      }
    }),
    founderGenders,
    founderEthnicities,
    legalStructure,
    employees,
    region,
  }
}

const mapObject = (object: any, func: any, impactResponse: ImpactResponseType) => {
  const result = {}
  Object.keys(object).forEach(key => {
    result[key] = func(object[key], impactResponse)
  })
  return result
}

export const getSupplierImpactForOrder = (
  productImpact: ProductImpactType,
  order: ImpactOrderType
) => {
  if (productImpact.reportingType === "SPEND") {
    return (order.spend / 100) * productImpact.amount
  } else {
    return order.quantity * productImpact.amount
  }
}
